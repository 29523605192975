/** @jsx jsx */
import { jsx, Box, Styled, Flex } from 'theme-ui'
import { Fragment } from 'react'
import { animated, useSpring } from 'react-spring'
import Container from './container'
import Wave from './wave'

const AnimatedBox = animated(Box)

const AboutContentApproach = () => {
  const subtitleFade = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    delay: 350,
  })

  return (
    <Fragment>
      <Box
        sx={{
          variant: 'layout.sectionContainer',
          bg: 'inherit',
          width: '100vw',
          position: 'relative',
          ml: '-50vw',
          left: '50%',
          overflow: 'hidden',
          // mt: '-2px',
        }}
      >
        <Wave wave="aboutApproach" transformFrom="bottom" height="auto" />
        <Container>
          <Box sx={{ mt: 4, mb: 0, px: [1, 4, 4] }}>
            <Flex sx={{ my: 0, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
              <Box sx={{ mx: 0, bg: 'headingSecondary', width: ['20px', '110px', '130px', '130px'], height: '2px', boxShadow: (theme) => `0 0px 0px 0 ${theme.colors.headingSecondary}`, borderRadius: '2px' }} />
              <Box sx={{ mx: 2, textAlign: 'center' }}>
                <Styled.p sx={{ fontSize: 3, variant: 'text.caps', color: 'headingSecondary' }}>Our Approach</Styled.p>
              </Box>
              <Box sx={{ mx: 0, bg: 'headingSecondary', width: ['20px', '110px', '130px', '130px'], height: '2px', boxShadow: (theme) => `0 0px 0px 0 ${theme.colors.headingSecondary}`, borderRadius: '2px' }} />
            </Flex>

            <AnimatedBox
              style={subtitleFade}
              sx={{
                width: ['100%', '75%', '75%'],
                mx: 'auto',
                pt: [3, 4, 4],
                pb: [1, 1, 1],
                px: [1, 3, 6],
                textAlign: 'center',
              }}
            >
              <Styled.p sx={{ fontSize: '3', variant: 'text.about', color: 'text' }}>We build websites and apps that work the way you and your users need them to.</Styled.p>
              <Styled.p sx={{ fontSize: '3', variant: 'text.about', color: 'text' }}>
                We start by shaping your concept. This is a creative process - it's the up-front work we do to set boundaries and reduce project risk. We consider interface ideas, technical possibilities, and business priorities, including budget, to produce well-defined product elements before the
                build.
              </Styled.p>
              <Styled.p sx={{ fontSize: '3', variant: 'text.about', mb: 0, color: 'text' }}>
                We reduce risk in the build process by integrating design and programming early on, working in cycles to build meaningful pieces of the work, end-to-end, rather than building lots of disconnected parts hoping they’ll fit together in the 11th hour. Then we repeat. Together we test as
                we go.
              </Styled.p>
            </AnimatedBox>
          </Box>
        </Container>
      </Box>
    </Fragment>
  )
}

export default AboutContentApproach
