/** @jsx jsx */
import { jsx, Box, Styled, Flex, useThemeUI } from 'theme-ui'
import { Fragment } from 'react'
import { animated, useSpring } from 'react-spring'
import Container from './container'
import Blob from './blob'

const AnimatedBox = animated(Box)

const AboutContentAbout = () => {
  const context = useThemeUI()
  const { theme } = context
  const subtitleFade = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    delay: 350,
  })

  return (
    <Fragment>
      <Box
        sx={{
          variant: 'layout.sectionContainer',
          bg: 'inherit',
          width: '100vw',
          position: 'relative',
          ml: '-50vw',
          left: '50%',
          overflow: 'hidden',
        }}
      >
        <Blob blob="workCaseStudies" positionTop="-50%" positionLeft="40%" />
        <Container>
          <Box sx={{ my: 4, mt: [3, 4, 5], px: [1, 4, 4] }}>
            <Flex sx={{ my: 0, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
              <Box sx={{ mx: 0, bg: 'headingSecondary', width: ['100px', '130px', '130px'], height: '2px', boxShadow: (theme) => `0 0px 0px 0 ${theme.colors.headingSecondary}`, borderRadius: '2px' }} />
              <Box sx={{ mx: 2, textAlign: 'center' }}>
                <Styled.p sx={{ fontSize: 3, variant: 'text.caps', color: 'headingSecondary' }}>About</Styled.p>
              </Box>
              <Box sx={{ mx: 0, bg: 'headingSecondary', width: ['100px', '130px', '130px'], height: '2px', boxShadow: (theme) => `0 0px 0px 0 ${theme.colors.headingSecondary}`, borderRadius: '2px' }} />
            </Flex>

            <AnimatedBox
              style={subtitleFade}
              sx={{
                width: ['100%', '76%', '76%'],
                mx: 'auto',
                pt: [3, 4, 4],
                pb: [3, 4, 4],
                px: [1, 3, 6],
                textAlign: 'center',
              }}
            >
              <Styled.p sx={{ fontSize: '3', variant: 'text.about', color: 'text', border: '1px solid red' }}>
                Gramercy Studios is a creative technology company that provides custom web development and product design. We’re a small team, curious, open-minded, and collaborative. We love what we do. Headquartered in{' '}
                <a href="https://goo.gl/maps/1XyP3M6JakpS7ziu8">
                  <span
                    sx={{
                      color: theme.colors.headingSecondary,
                      position: 'relative',
                      display: 'inline-block',
                      '::after': {
                        content: '""',
                        position: 'absolute',
                        // zIndex: '-1',
                        top: '60%',
                        left: '-0.1em',
                        right: '-0.1em',
                        bottom: '0',
                        backgroundColor: theme.colors.externalLinkTo,
                        opacity: 0.4,
                        transition: 'top 700ms ease-out',
                      },
                      ':hover:after': {
                        opacity: 0.4,
                        top: '5%',
                      },
                    }}
                  >
                    Perth, Western Australia
                  </span>
                </a>
                , we are fully remote and distributed, allowing us to collaborate and work with some fantastic people and clients from around the world.
              </Styled.p>
              <Styled.p sx={{ fontSize: '3', variant: 'text.about', mb: 0, color: 'text' }}>We envision better digital spaces where compelling experiences are accessible to everyone, and together we aim to build products that help to create a better Web.</Styled.p>
            </AnimatedBox>
          </Box>
        </Container>
      </Box>
    </Fragment>
  )
}

export default AboutContentAbout
