/** @jsx jsx */
import { jsx, Flex } from 'theme-ui'
import SEO from './SEO/seo'
import Layout from './layout'
import { useLocation } from '@reach/router'
import AboutHero from './aboutHero'
import AboutContentAbout from './aboutContentAbout'
import AboutContentApproach from './aboutContentApproach'
import AboutContentBuildingFor from './aboutContentBuildingFor'
import ContactSection from './contactSection'

import { Fragment } from 'react'

const About = () => {
  const { pathname } = useLocation()
  return (
    <Fragment>
      <Layout location={pathname}>
        <SEO title="About" description="Gramercy is a product design and creative technology company." />
        <Flex
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {/* <AboutContent /> */}
          <AboutHero />
          {/* <AboutContentAbout /> */}
          <AboutContentBuildingFor />
          <AboutContentApproach />
          <ContactSection variant="contact" heading="Let us tell you more" buttonLabel="Start a conversation" linkToFirstButton="/contact/" secondButton={true} secondLabel="See our work" variantSecondButton="outline" linkToSecondButton="/work/" withWave={true} />
        </Flex>
      </Layout>
    </Fragment>
  )
}

export default About
