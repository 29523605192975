/** @jsx jsx */
import { jsx, Box, Styled, Flex } from 'theme-ui'
import { Fragment } from 'react'
import { animated, useSpring } from 'react-spring'
import Container from './container'
import BookACall from './bookACall'

const AnimatedFlex = animated(Flex)
const AnimatedBox = animated(Box)

const AboutContentBuildingFor = () => {
  const headingFade = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    delay: 250,
  })
  const subtitleFade = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    delay: 350,
  })

  return (
    <Fragment>
      <Box
        sx={{
          variant: 'layout.sectionContainer',
          bg: 'inherit',
          width: '100vw',
          position: 'relative',
          ml: '-50vw',
          left: '50%',
          overflow: 'hidden',
          // mt: '-2px',
        }}
      >
        <Container>
          <Box sx={{ my: 4, px: [1, 4, 4] }}>
            <Flex sx={{ my: 0, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
              <Box sx={{ mx: 0, bg: 'headingSecondary', width: ['20px', '110px', '130px', '130px'], height: '2px', boxShadow: (theme) => `0 0px 0px 0 ${theme.colors.headingSecondary}`, borderRadius: '2px' }} />
              <Box sx={{ mx: 2, textAlign: 'center' }}>
                <Styled.p sx={{ fontSize: 3, variant: 'text.caps', color: 'headingSecondary' }}>Building For The Modern Web</Styled.p>
              </Box>
              <Box sx={{ mx: 0, bg: 'headingSecondary', width: ['20px', '110px', '130px', '130px'], height: '2px', boxShadow: (theme) => `0 0px 0px 0 ${theme.colors.headingSecondary}`, borderRadius: '2px' }} />
            </Flex>

            <AnimatedBox
              style={subtitleFade}
              sx={{
                width: ['100%', '75%', '75%'],
                mx: 'auto',
                pt: [3, 4, 4],
                pb: [3, 4, 4],
                px: [1, 3, 6],
                textAlign: 'center',
              }}
            >
              <Styled.p sx={{ fontSize: '3', variant: 'text.about', color: 'text', mb: 0 }}>
                The Web is rapidly changing. As an organisation we embrace this and work hard to make sure we keep up. This gives us a unique understanding of the types of technologies that can be leveraged to execute a given creative concept.{' '}
              </Styled.p>
              <Styled.p sx={{ fontSize: '3', variant: 'text.about', color: 'text', mb: 0 }}>We build for Web2 and Web3, in particular EVM (Ethereum) compatible smart contracts, and frontends that iteract with smart contracts in the rapidly evolving blockchain ecosytem. </Styled.p>
            </AnimatedBox>
            <AnimatedFlex style={headingFade} sx={{ my: 0, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
              <Box sx={{ mx: 2, textAlign: 'center', maxWidth: ['55%', '50%', '50%', '60%'] }}>
                <Styled.p sx={{ fontSize: 2, variant: 'text.form', color: 'headingSecondary' }}>Like to know more?</Styled.p>
              </Box>
            </AnimatedFlex>
            <AnimatedFlex style={headingFade} sx={{ my: 0, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
              <Box sx={{ mx: 2, textAlign: 'center', maxWidth: ['55%', '50%', '50%', '60%'] }}>
                <Styled.p sx={{ fontSize: 2, variant: 'text.form', color: 'headingSecondary' }}> Book an introductory call with</Styled.p>
              </Box>
            </AnimatedFlex>
            <BookACall />
          </Box>
        </Container>
      </Box>
    </Fragment>
  )
}

export default AboutContentBuildingFor
