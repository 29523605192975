/** @jsx jsx */
import { jsx, Box, Styled, Flex, useThemeUI } from 'theme-ui'
import { Fragment, useState } from 'react'
import { animated, useSpring } from 'react-spring'
import { keyframes } from '@emotion/core'
import Container from './container'
import Blob from './blob'

const swingingForwards = keyframes`
  0% {
    transform: rotateZ(122deg) translate(140.22%,-52.2%);
    ${'' /* transform: rotateZ(122deg) translate(59px,-47px); */}
    opacity: 1;
    }
  15% {
    transform: rotateZ(122deg) translate(140.22%,-52.2%);
    ${'' /* transform: rotateZ(122deg) translate(59px,-47px); */}
    opacity: 0.6;
    }
  35% {
    transform: rotateZ(122deg) translate(140.22%,-52.2%);
    ${'' /* transform: rotateZ(122deg) translate(59px,-47px); */}
    opacity: 0.4;
  }
  55% {
    transform: rotateZ(0deg) translateX(0px);
    opacity: 0.5;
  }
  65% {
    opacity: 0.7;
  }
  75% {
    opacity: 0.8;
  }
  85% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
`

const swingingBackwards = keyframes`
  0% {
    transform: rotateZ(0deg) translateX(0px);
    opacity: 1;
    }
  15% {

    opacity: 0.6;
    }
  35% {

    opacity: 0.4;
  }
  55% {

    opacity: 0.5;
  }
  65% {
    opacity: 0.7;
    transform: rotateZ(122deg) translate(140.22%,-52.2%);
    ${'' /* transform: rotateZ(122deg) translate(59px,-47px); */}
  }
  75% {
    opacity: 0.8;
  }
  85% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
    transform: rotateZ(122deg) translate(140.22%,-52.2%);
    ${'' /* transform: rotateZ(122deg) translate(59px,-47px); */}
  }
`

const AnimatedBox = animated(Box)

const AboutHero = () => {
  const [ySelected, setYSelected] = useState(false)

  const context = useThemeUI()
  const { theme } = context
  const headingFade = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    delay: 200,
  })
  const subtitleFade = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    delay: 350,
  })

  function handleYClick() {
    setYSelected((prevState) => !prevState)
  }

  const flicker = keyframes`
  0%, 19.999%, 22%, 62.999%, 64%, 64.999%, 70%, 100% {
		opacity: 0.4;
    text-shadow: none;
    }
	20%, 21.999%, 63%, 63.999%, 65%, 69.999% {
		opacity: 1;
		text-shadow:
      0 0 20px ${theme.colors.headingNeonShadow},
      0 0 2.5vmin ${theme.colors.headingNeonShadow},
      0 0 5vmin ${theme.colors.headingNeonShadow},
      0 0 10vmin ${theme.colors.headingNeonShadow},
      0 0 15vmin ${theme.colors.headingNeonShadow}
    }
	}
`

  const illuminateUp = keyframes`
  0% {
		opacity: 0.4;
    text-shadow:
      none
    }
  25% {
		opacity: 0.4;
    text-shadow:
      none
    }
    }
  30% {
		opacity: 1;
    text-shadow:
      0 0 20px ${theme.colors.headingNeonShadow},
      0 0 2.5vmin ${theme.colors.headingNeonShadow},
      0 0 5vmin ${theme.colors.headingNeonShadow},
      0 0 10vmin ${theme.colors.headingNeonShadow},
      0 0 15vmin ${theme.colors.headingNeonShadow}
    }
    }
	100% {
		opacity: 1;
		text-shadow:
      0 0 20px ${theme.colors.headingNeonShadow},
      0 0 2.5vmin ${theme.colors.headingNeonShadow},
      0 0 5vmin ${theme.colors.headingNeonShadow},
      0 0 10vmin ${theme.colors.headingNeonShadow},
      0 0 15vmin ${theme.colors.headingNeonShadow}
    }
	}
`

  const illuminateDown = keyframes`
  0% {
		opacity: 1;
    text-shadow:
      0 0 20px ${theme.colors.headingNeonShadow},
      0 0 2.5vmin ${theme.colors.headingNeonShadow},
      0 0 5vmin ${theme.colors.headingNeonShadow},
      0 0 10vmin ${theme.colors.headingNeonShadow},
      0 0 15vmin ${theme.colors.headingNeonShadow}
    }
    }
  25% {
  opacity: 0.4;
  text-shadow:
      0 0 20px ${theme.colors.headingNeonShadow},
      0 0 2.5vmin ${theme.colors.headingNeonShadow},
      0 0 5vmin ${theme.colors.headingNeonShadow},
      0 0 10vmin ${theme.colors.headingNeonShadow},
      0 0 15vmin ${theme.colors.headingNeonShadow}
    }
	100% {
		opacity: 0.4;
		text-shadow:
      none
    }
	}
`

  const CustomSpan = ({ children, duration = '2s' }) => {
    return (
      <span
        sx={{
          color: theme.colors.headingNeon,
          textShadow: ySelected
            ? `0 0 20px ${theme.colors.headingNeonShadow},
                 0 0 2.5vmin ${theme.colors.headingNeonShadow},
                 0 0 5vmin ${theme.colors.headingNeonShadow},
                 0 0 10vmin ${theme.colors.headingNeonShadow},
                 0 0 15vmin ${theme.colors.headingNeonShadow}
                `
            : 'none',
          opacity: ySelected ? '1' : '0.4',
          position: 'relative',
          display: 'inline-block',
          animation: ySelected ? `${illuminateUp} ${duration} linear` : `${illuminateDown} ${duration} linear`,
        }}
      >
        {children}
      </span>
    )
  }

  const CustomButtonY = ({ children, onClick }) => {
    return (
      <button
        onClick={onClick}
        aria-label="Animate the letter 'y'"
        title="Animate the letter 'y'"
        sx={{
          background: 'none',
          border: 'none',
          p: '0px',
          m: '0px',
          position: 'relative',
          display: 'inline-block',
          zIndex: '200',
          cursor: 'pointer',
          animation: ySelected ? `${swingingForwards} 2s cubic-bezier(0.175, 0.885, 0.320, 1.275) forwards` : `${swingingBackwards} 2s cubic-bezier(0.175, 0.885, 0.320, 1.275) forwards`,
        }}
      >
        <h1
          sx={{
            fontSize: [5, 7, 8],
            my: 0,
            mx: [0, 0, 0],
            // zIndex: '200',
            fontFamily: theme.fonts.body,
            lineHeight: theme.lineHeights.heading,
            fontWeight: theme.fontWeights.heading,
            color: theme.colors.headingNeon,
            textShadow: ySelected
              ? `0 0 20px ${theme.colors.headingNeonShadow},
                   0 0 2.5vmin ${theme.colors.headingNeonShadow},
                   0 0 5vmin ${theme.colors.headingNeonShadow},
                   0 0 10vmin ${theme.colors.headingNeonShadow},
                   0 0 15vmin ${theme.colors.headingNeonShadow}
                  `
              : 'none',
            '@media (prefers-reduced-motion: no-preference)': { animation: ySelected ? 'none' : `${flicker} 6s linear infinite` },
            animation: ySelected ? 'none' : `none`,
          }}
        >
          {children}
        </h1>
      </button>
    )
  }

  return (
    <Fragment>
      <Box
        sx={{
          variant: 'layout.sectionContainer',
          bg: 'inherit',
          width: '100vw',
          position: 'relative',
          ml: '-50vw',
          left: '50%',
          overflow: 'hidden',
        }}
      >
        <Blob blob="aboutHero" positionTop="15%" positionLeft="40%" />

        <Container>
          <AnimatedBox
            style={headingFade}
            sx={{
              textAlign: 'center',
              mt: [4, 5, 6],
            }}
          >
            <Styled.h1 sx={{ variant: 'text.heading' }}>
              <span
                sx={{
                  color: theme.colors.headingNeon,
                  textShadow: `0 0 20px ${theme.colors.headingNeonShadow},
                 0 0 2.5vmin ${theme.colors.headingNeonShadow},
                 0 0 5vmin ${theme.colors.headingNeonShadow},
                 0 0 10vmin ${theme.colors.headingNeonShadow},
                 0 0 15vmin ${theme.colors.headingNeonShadow}
                `,
                }}
              >
                Gramercy
              </span>{' '}
              is a product design and creative{' '}
              <span sx={{ whiteSpace: 'nowrap' }}>
                <CustomSpan>technolog</CustomSpan>
                {/* <CustomSpan>e</CustomSpan>
            <CustomSpan>c</CustomSpan>
            <CustomSpan>h</CustomSpan>
            <CustomSpan>n</CustomSpan>
            <CustomSpan>o</CustomSpan>
            <CustomSpan>l</CustomSpan>
            <CustomSpan>o</CustomSpan>
            <CustomSpan>g</CustomSpan> */}
                <CustomButtonY onClick={handleYClick}>y</CustomButtonY>
              </span>{' '}
              company.
            </Styled.h1>
          </AnimatedBox>
          <AnimatedBox
            style={subtitleFade}
            sx={{
              px: [1, 5, 6],
              textAlign: 'center',
            }}
          >
            <Styled.h4 sx={{ variant: 'text.subheading' }}>We help progressive organisations create interactive product-driven products for the Web.</Styled.h4>
          </AnimatedBox>
          <Box sx={{ my: 4, mt: [3, 4, 5], px: [1, 4, 4] }}>
            <Flex sx={{ mt: ['4', '4', '5', '5'], mb: 0, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
              <Box sx={{ mx: 0, bg: 'headingSecondary', width: ['20px', '100px', '130px', '130px'], height: '2px', boxShadow: (theme) => `0 0px 0px 0 ${theme.colors.headingSecondary}`, borderRadius: '2px' }} />
              <Box sx={{ mx: 2, textAlign: 'center' }}>
                <Styled.p sx={{ fontSize: 3, variant: 'text.caps', color: 'headingSecondary' }}>About</Styled.p>
              </Box>
              <Box sx={{ mx: 0, bg: 'headingSecondary', width: ['20px', '100px', '130px', '130px'], height: '2px', boxShadow: (theme) => `0 0px 0px 0 ${theme.colors.headingSecondary}`, borderRadius: '2px' }} />
            </Flex>

            <AnimatedBox
              style={subtitleFade}
              sx={{
                width: ['100%', '76%', '76%'],
                mx: 'auto',
                pt: [3, 4, 4],
                pb: [3, 4, 4],
                px: [1, 3, 6],
                textAlign: 'center',
              }}
            >
              <Styled.p sx={{ fontSize: '3', variant: 'text.about', color: 'text' }}>
                Gramercy Studios is a creative technology company that provides custom web development and product-driven design. We’re a small team, curious, open-minded, and collaborative. With a physical presence in{' '}
                <a href="https://goo.gl/maps/1XyP3M6JakpS7ziu8">
                  <span
                    sx={{
                      color: theme.colors.headingSecondary,
                      position: 'relative',
                      display: 'inline-block',
                      '::after': {
                        content: '""',
                        position: 'absolute',
                        // zIndex: '-1',
                        top: '60%',
                        left: '-0.1em',
                        right: '-0.1em',
                        bottom: '0',
                        backgroundColor: theme.colors.externalLinkTo,
                        opacity: 0.4,
                        transition: 'top 700ms ease-out',
                      },
                      ':hover:after': {
                        opacity: 0.4,
                        top: '5%',
                      },
                    }}
                  >
                    Perth
                  </span>
                </a>{' '}
                and{' '}
                <a href="https://goo.gl/maps/AR2D927HWLFg4d517">
                  <span
                    sx={{
                      color: theme.colors.headingSecondary,
                      position: 'relative',
                      display: 'inline-block',
                      '::after': {
                        content: '""',
                        position: 'absolute',
                        // zIndex: '-1',
                        top: '60%',
                        left: '-0.1em',
                        right: '-0.1em',
                        bottom: '0',
                        backgroundColor: theme.colors.externalLinkTo,
                        opacity: 0.4,
                        transition: 'top 700ms ease-out',
                      },
                      ':hover:after': {
                        opacity: 0.4,
                        top: '5%',
                      },
                    }}
                  >
                    Brisbane
                  </span>
                </a>
                , and a remote distributed team, we get to collaborate and work with some fantastic people and clients not only in Australia but from around the world.
              </Styled.p>
              <Styled.p sx={{ fontSize: '3', variant: 'text.about', mb: 0, color: 'text' }}>In addition to traditional web products, we provide Web3 education and development, empowering individuals and organisations to participate in the new open web built on blockchain technology.</Styled.p>
              <Styled.p sx={{ fontSize: '3', variant: 'text.about', mb: 0, color: 'text' }}>We envision better digital spaces where compelling experiences are accessible to everyone, and together we aim to build products that help to create a better, progressively decentralised open web.</Styled.p>
            </AnimatedBox>
          </Box>
        </Container>
      </Box>
    </Fragment>
  )
}

export default AboutHero
