import React from 'react'
import About from '../components/about'


const AboutPageTemplate = () => {

  return <About />
}

export default AboutPageTemplate
